<template>
    <div
        class="flex w-full flex-col duration-500 ease-in-out space-y-2 divide-y divide-gray-600 transition-spacing"
        :class="{ 'bg-black/40 py-2 shadow-inner shadow-black/30': selectedSection }"
    >
        <router-link
            class="flex-1 rounded-md font-semibold uppercase tracking-wide hover:bg-black/30 hover:text-white focus:text-white focus:outline-none"
            :class="{
                'text-white': sectionActive,
                'bg-black/40 shadow-inner shadow-black/30': sectionActive && !selectedSection,
                'text-gray-300': !sectionActive,
            }"
            :to="{ name: sectionRoute }"
            @click.native="navigateSection"
        >
            <div
                class="flex w-full items-center py-2 transition-spacing duration-500 space-x-4"
                :class="collapsed ? 'px-2' : 'px-4'"
                :title="selectedSection ? goBackTitleTooltip : tooltip"
            >
                <div class="flex h-6 w-6 shrink-0 items-center justify-center">
                    <base-icon
                        :name="selectedSection ? 'chevron-circle-left' : sectionIcon.name"
                        :prefix="selectedSection ? 'fas' : sectionIcon.prefix"
                    />
                </div>
                <span class="overflow-hidden whitespace-nowrap leading-none">
                    {{ selectedSection ? goBackTitle : title }}
                </span>
            </div>
        </router-link>
        <div class="flex flex-1 flex-col divide-y divide-gray-700" v-if="selectedSection">
            <div v-if="!isActiveAuthorization" class="flex-1 py-2">
                <base-tooltip
                    class="w-full"
                    content="We've lost access to your platform. Please click here to re-establish the connection."
                >
                    <base-button
                        class="w-full"
                        type="warning"
                        content-class="flex h-5 max-w-full shrink-0 items-center justify-center overflow-hidden"
                        :custom-size-class="collapsed ? 'px-0.5 py-2': 'px-3 py-2'"
                        @click="reconnect"
                    >
                        <base-icon
                            class="duration-500 ease-in-out transition-spacing"
                            :class="collapsed ? 'mx-3' : 'mr-3'"
                            name="plug"
                            prefix="fas"
                        />
                        <div class="overflow-hidden font-bold uppercase leading-none tracking-wide">
                            {{ reconnectTitle }}
                        </div>
                    </base-button>
                </base-tooltip>
            </div>
            <div class="flex flex-col space-y-2 divide-y divide-gray-700">
                <div class="flex flex-col pt-2">
                    <base-button
                        type="primary"
                        content-class="flex h-5 max-w-full shrink-0 items-center justify-center overflow-hidden"
                        :custom-size-class="collapsed ? 'px-0.5 py-2': 'px-3 py-2'"
                        :disabled="createDisabled"
                        @click="create"
                    >
                        <base-icon
                            class="duration-500 shrink-0 ease-in-out transition-spacing"
                            :class="collapsed ? 'mx-3' : 'mr-3'"
                            name="plus"
                            prefix="fas"
                            size="sm"
                        />
                        <div class="overflow-hidden whitespace-nowrap pt-px font-bold uppercase leading-none tracking-wide">
                            {{ createTitle }}
                        </div>
                    </base-button>
                </div>
                <div class="flex flex-col pt-2 space-y-1">
                    <router-link
                        v-for="(button, index) in nestedSections"
                        v-slot="{ href, navigate, isActive }"
                        :key="index"
                        :to="button.route"
                        custom
                        @click.native="navigateSection"
                    >
                        <a
                            :href="href"
                            class="flex-1 rounded-md font-bold uppercase tracking-wide hover:bg-gray-700 hover:text-white focus:text-white focus:outline-none"
                            :title="collapsed ? button.label : undefined"
                            :class="{
                                'text-white bg-gray-800': isActive,
                                'text-gray-300': !isActive,
                            }"
                            @click="navigate"
                        >
                            <div class="flex w-full items-center px-3 py-2">
                                <div
                                    class="flex h-4 w-4 shrink-0 items-center justify-center duration-500 ease-in-out transition-spacing"
                                    :class="collapsed ? 'mr-4' : 'mr-4'"
                                >
                                    <base-icon
                                        class="h-4 w-4"
                                        :name="button.icon.name"
                                        :prefix="button.icon.prefix"
                                    />
                                </div>
                                <span
                                    class="overflow-hidden whitespace-nowrap text-sm leading-none duration-500 ease-in-out transition-size"
                                    :class="collapsed ? 'md:w-0' : 'w-44'"
                                >
                                    {{ button.label }}
                                </span>
                            </div>
                        </a>
                    </router-link>
                </div>
                <slot name="under-section" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name:     'SidebarNestedSection',
    props:    {
        sectionName:           {
            type:     String,
            required: true,
        },
        sectionRoute:          {
            type:     String,
            required: true,
        },
        sectionActive:         {
            type:     Boolean,
            required: true,
        },
        sectionIcon:           {
            type:     Object,
            required: true,
        },
        nestedSections:        {
            type:     Array,
            required: true,
        },
        title:                 {
            type:     String,
            required: true,
        },
        tooltip:               {
            type:     String,
            required: true,
        },
        goBackTitle:           {
            type:     String,
            required: true,
        },
        goBackTitleTooltip:    {
            type:     String,
            required: true,
        },
        reconnectTitle:        {
            type:     String,
            required: true,
        },
        createTitle:           {
            type:     String,
            required: true,
        },
        isActiveAuthorization: {
            type:     Boolean,
            required: true,
        },
        isLocked:              {
            type:     Boolean,
            required: true,
        },
    },
    computed: {
        ...mapState('sidebar', {
            collapsed: 'collapsed',
        }),
        selectedSection() {
            return this.$route.path.match(`/${this.sectionName}/[0-9]+/.*`) !== null;
        },
        createDisabled() {
            return this.isLocked || !this.isActiveAuthorization;
        },
    },
    methods:  {
        create() {
            if (!this.createDisabled) {
                this.$emit('create');
            }
        },
        reconnect() {
            this.$emit('reconnect');
        },
        navigateSection() {
            this.$emit('navigate');
        },
    },
};
</script>
